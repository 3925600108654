import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { FaCar, FaCogs, FaPaintBrush, FaTachometerAlt } from "react-icons/fa";
import { mapValues, groupBy, omit } from "lodash";
import {
  collection,
  onSnapshot,
  orderBy,
  where,
  query,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { auth, db } from "../../firebaseConfig";

import { useAuthState } from "react-firebase-hooks/auth";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";
import CarList from "../../components/CarList";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollToTop";

const CarListingPage = () => {
  const { t } = useTranslation();

  

  return (
    <Fragment>
      <ScrollToTop/>
      <Header />
      <PageTitle
        pageTitle={t("header-navigation.car_listing")}
        pagesub={t("header-navigation.car_listing")}
      />
      <CarList />
      <Footer />
    </Fragment>
  );
};
export default CarListingPage;

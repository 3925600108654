import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../img/testimonial.jpg";
import img2 from "../../img/testimonial-2.jpg";

import "./style.css";

const Testimonial = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-testimonial-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("some_words")}</h4>
              <h2>{t("testimonial")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="testimonial-slider" {...settings}>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                    "I recently rented a car from Auto Kings Motor, and my overall experience was highly satisfactory. The booking process was effortless, the staff was friendly and professional, and the vehicle I rented was in excellent condition. The return process was quick and efficient, ensuring a hassle-free experience. I would recommend Auto Kings Motor for their user-friendly service and well-maintained vehicles."

                    </p>
                    <div className="testimonial-meta">
                     
                      <div className="client-info">
                        <h3>Jason Low</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "Renting a red Lamborghini Aventador from Auto Kings Motor was an absolute thrill. From the moment I picked up the keys, I felt like a superstar. The car was in pristine condition, turning heads wherever I went. The power and performance of the Aventador were unmatched, delivering an adrenaline-fueled driving experience. The staff at Auto Kings Motor were professional and accommodating, ensuring a seamless rental process."
                    </p>
                    <div className="testimonial-meta">
                    
                      <div className="client-info">
                        <h3>Mitchell Lim</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "I had the pleasure of renting a car from Auto Kings Motor for my wedding, and it was a decision I couldn't be happier with. The process was effortless and they where  incredibly helpful in guiding me through the selection of the perfect vehicle for my special day. The car I rented was immaculate and added a touch of elegance to the occasion. It arrived on time, beautifully decorated, they made my wedding day unforgettable."
                    </p>
                    <div className="testimonial-meta">
                     
                      <div className="client-info">
                        <h3>Nick Yong</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "Renting a McLaren from Auto Kings Motor for a week was an absolute dream come true. The entire experience, from the reservation process to returning the car, was flawless. The McLaren was in impeccable condition, delivering an exhilarating driving experience that exceeded all expectations. If you're seeking an unforgettable adventure behind the wheel of a high-performance luxury car, I highly recommend Auto Kings Motor."
                    </p>
                    <div className="testimonial-meta">
                      
                      <div className="client-info">
                        <h3>Sebestian Tan</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "From the moment I stepped into their office, I felt the excitement building. The staff was friendly and accommodating, ensuring that I had all the necessary information to make the most of my experience. The Maserati I rented was a true masterpiece.Auto Kings Motor provided a seamless rental process, allowing me to focus on enjoying the thrilling ride and not having to worry about anything else arround it! " 
                    </p>
                    <div className="testimonial-meta">
                      
                      <div className="client-info">
                        <h3>Lisa Lim</h3>
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { FaCar, FaCogs, FaPaintBrush, FaTachometerAlt } from "react-icons/fa";
import { mapValues, groupBy, omit } from "lodash";
import {
  collection,
  onSnapshot,
  orderBy,
  where,
  query,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { auth, db } from "../../firebaseConfig";

import { useAuthState } from "react-firebase-hooks/auth";

import img1 from "../../img/offer-toyota.png";
import img2 from "../../img/lambo-offer.png";
import img3 from "../../img/audi-offer.png";
import img4 from "../../img/mclaren-offer.png";
import img5 from "../../img/toyota-offer-2.png";
import img6 from "../../img/marcedes-offer.png";
import img7 from "../../img/audi2-offer.png";
import img8 from "../../img/bmw-offer.png";

import "./style.css";

const HotOffers = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  const [articles, setArticles] = useState([]);

  let hello;
  const [filtered, setFiltered] = useState([]);
  const [bmw, setBmw] = useState([]);
  const [lambo, setLambo] = useState([]);
  const [mclaren, setMclaren] = useState([]);
  const [maserati, setMaserati] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const articleRef = collection(db, "Cars");
    const q = query(articleRef,  where("hotoffer", "==", "Yes"));

    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      hello = articles.reduce(
        (entryMap, e) =>
          entryMap.set(e.title, [...(entryMap.get(e.title) || []), e]),
        new Map()
      );
      console.log("hello!: ", hello);

      setArticles(articles);
      console.log('hot offers: ', articles);
    });
  }, []);

  function testme(ead) {
    useEffect(() => {
      const filteredRef = collection(db, "Cars");
      const qu = query(filteredRef, where("title", "==", ead));
      onSnapshot(qu, (snapshot) => {
        const filtered = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFiltered(filtered);
        console.log(filtered);
      });
    }, []);
  }

  useEffect(() => {
    const filteredRef = collection(db, "Cars");
    const qu = query(filteredRef, where("title", "==", "Audi" ) , where("hotoffer", "==", "Yes"));
    onSnapshot(qu, (snapshot) => {
      const filtered = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFiltered(filtered);
      console.log(filtered);
    });
  }, []);


  useEffect(() => {
    const maseratiRef = collection(db, "Cars");
    const qu = query(maseratiRef, where("title", "==", "Maserati" ) , where("hotoffer", "==", "Yes"));
    onSnapshot(qu, (snapshot) => {
      const maserati = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMaserati(maserati);
      console.log(maserati);
    });
  }, []);


  useEffect(() => {
    const bmwRef = collection(db, "Cars");
    const qu = query(bmwRef, where("title", "==", "BMW"), where("hotoffer", "==", "Yes"));
    onSnapshot(qu, (snapshot) => {
      const bmw = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBmw(bmw);
      console.log(bmw);
    });
  }, []);

  useEffect(() => {
    const lamboRef = collection(db, "Cars");
    const qu = query(lamboRef, where("title", "==", "Lamborghini"), where("hotoffer", "==", "Yes"));
    onSnapshot(qu, (snapshot) => {
      const lambo = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLambo(lambo);
      console.log(lambo);
    });
  }, []);

  useEffect(() => {
    const mclarenRef = collection(db, "Cars");
    const qu = query(mclarenRef, where("title", "==", "McLaren"), where("hotoffer", "==", "Yes"));
    onSnapshot(qu, (snapshot) => {
      const mclaren = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMclaren(mclaren);
      console.log(mclaren);
    });
  }, []);

  return (
    <section className="gauto-offers-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("come_with")}</h4>
              <h2>{t("hot_offers")}</h2>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="offer-tabs" id="offerTab">
              <Tabs
                defaultActiveKey="all"
                transition={true}
                id="uncontrolled-tab-example"
              >
                {/* All Brands Start */}
                <Tab eventKey="all" title="All Brands">
                  <Row>
                    {articles.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      articles.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                                <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                                <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
                </Tab>
               
               
                <Tab eventKey="Audi" title="Audi">
                  <Row>
                    {filtered.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      filtered.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                                <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                                <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="BMW" title="BMW">
                  <Row>
                    {bmw.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      bmw.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                                <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                                <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="Lambo" title="Lamborghini">
                  <Row>
                    {lambo.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      lambo.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                                <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                                <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="Maserati" title="Maserati">
                  <Row>
                    {mclaren.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      maserati.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                              <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                                <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
                </Tab>
                <Tab eventKey="McLaren" title="McLaren">
                  <Row>
                    {mclaren.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      mclaren.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                              <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                                <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
                </Tab>
                {/* All Brands End */}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HotOffers;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaCar,
  FaCogs,
  FaTachometerAlt,
  FaAngleDoubleRight,
  FaPaintBrush,
} from "react-icons/fa";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";








import { mapValues, groupBy, omit } from "lodash";
import {
  collection,
  onSnapshot,
  orderBy,
  where,
  query,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { auth, db } from "../../firebaseConfig";

import { useAuthState } from "react-firebase-hooks/auth";

import img2 from "../../img/nissan-offer.png";
import img3 from "../../img/audi-offer.png";
import img4 from "../../img/bmw-offer.png";
import img5 from "../../img/toyota-offer-2.png";
import img6 from "../../img/marcedes-offer.png";

import "./style.css";

const CarList = () => {
  const { t } = useTranslation();

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const [articles, setArticles] = useState([]);

  let hello;
  const [filtered, setFiltered] = useState([]);
  const [bmw, setBmw] = useState([]);
  const [lambo, setLambo] = useState([]);
  const [mclaren, setMclaren] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const articleRef = collection(db, "Cars");
    const q = query(articleRef,  );

    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      hello = articles.reduce(
        (entryMap, e) =>
          entryMap.set(e.title, [...(entryMap.get(e.title) || []), e]),
        new Map()
      );
      console.log("hello!: ", hello);

      setArticles(articles);
      console.log('hot offers: ', articles);
    });
  }, []);

  return (
    <section className="gauto-car-listing section_70">
      <Container>
        <Row>
        <Row>
                    {articles.length === 0 ? (
                      <div className="text-center">
                        {" "}
                        <h1> No cars found!</h1>
                      </div>
                    ) : (
                      articles.map(
                        ({
                          id,
                          title,
                          model,
                          year,
                          imageUrl,
                          color,
                          createdAt,
                          createdBy,
                          price,
                          userId,
                          transmission,
                          likes,
                          comments,
                        }) => (
                          <Col lg={4}>
                            <div className="single-offers">
                              <div className="offer-image">
                               <Link to={`/car/${id}`}>
                                  <img src={imageUrl} alt="offer 1" />
                                </Link>
                              </div>
                              <div className="offer-text">
                               <Link to={`/car/${id}`}>
                                  <h3>
                                    {title} {model}
                                  </h3>
                                </Link>
                                <h4>
                                  ${price}{" "}
                                  <span
                                    style={{
                                      color: "#e79e3e",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    / Day
                                  </span>
                                </h4>
                                <ul>
                                  <li>
                                    <li>
                                      <FaCar />
                                      &nbsp;Model : {year}
                                    </li>
                                  </li>
                                  <li>
                                    <FaCogs />
                                    &nbsp; {transmission}
                                  </li>

                                  <li>
                                    <FaPaintBrush />
                                    &nbsp; {color}
                                  </li>
                                </ul>
                                   <div className="offer-action">
                                   <Link to={`/rent/${id}`}
                                    className="offer-btn-1"
                                  >
                                    {t("rent_car")}
                                  </Link>
                                   <Link to={`/car/${id}`}
                                    className="offer-btn-2"
                                  >
                                    {t("details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      )
                    )}
                  </Row>
      
        </Row>
      </Container>
    </section>
  );
};

export default CarList;

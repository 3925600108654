import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import {
  FaStar,
  FaStarHalfAlt,
  FaCar,
  FaCogs,
  FaTachometerAlt,
  FaEmpire,
  FaDesktop,
  FaKey,
  FaLock,
  FaEye,
  FaPaintBrush,
  FaPaperPlane,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaPinterest,
  FaSkype,
  FaVimeo,
} from "react-icons/fa";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { auth, db } from "../../firebaseConfig";
import { Fragment } from "react";

import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";

import Footer from "../../components/Footer";

import "./style.css";
import Slider from "react-slick";
import ScrollToTop from "../ScrollToTop";

const Rent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const hostname = window.location.href;
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [comments, setComments] = useState([]);
  const [user] = useAuthState(auth);
  const [images, Setimages] = useState([]);
  const settings = {
    dots: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
  };

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  var curr1 = new Date();
  curr1.setDate(curr1.getDate() + 1);
  var date2 = curr1.toISOString().substring(0, 10);

  useEffect(() => {
    const docRef = doc(db, "Cars", id);
    onSnapshot(docRef, (snapshot) => {
      setArticle({ ...snapshot.data(), id: snapshot.id });
    });
  }, []);

  const commentRef = doc(db, "Cars", id);
  useEffect(() => {
    const docRef = doc(db, "Cars", id);
    onSnapshot(docRef, (snapshot) => {
      setComments(snapshot.data().comments);

      Setimages({
        thumbnail: snapshot.data().comments.comment,
        original: snapshot.data().comments.comment,
      });
    });
  }, []);

  console.log("testing images: ", images);
  console.log("ted comments: ", comments);
  return (
    <>
      <ScrollToTop />
      <Header />
      <PageTitle pageTitle="Rent A car" pagesub="Rent" />
      <section className="gauto-car-booking section_70">
        <Container>
          {article && (
            <section className="gauto-contact-area section_70">
              <Container>
                <Row>
                <Col lg={5} md={6} style={{ display: 'flex',
   alignItems: 'center'}}>
                 <div className="car-booking-image">
                 <img src={article.imageUrl}
              alt={article.title} style={{ width: "100%" }} />
               </div>

                 </Col>
                  <Col lg={7} md={6}>
                    <div className="contact-left">
                      <h3>
                        Inquire about {article.title} {article.model}
                      </h3>
                      <form
                        action="https://formsubmit.co/sales@autokings.sg"
                        method="POST"
                      >
                        <Row>
                          <Col md={6}>
                            <div className="single-contact-field">
                              <label> Car: </label>
                              <input
                                type="text"
                                name="car"
                                value={`${article.year} ${article.title} ${article.model} (${article.color})`}
                              />
                            </div>

                            <input
                              type="hidden"
                              name="_subject"
                              value={`${article.year} ${article.title} ${article.model} (${article.color}) - ${date}`}
                            ></input>
                            <input
                              type="hidden"
                              name="_template"
                              value="box"
                            ></input>
                            <input
                              type="hidden"
                              name="_next"
                              value={hostname}
                            ></input>
                          </Col>
                          <Col md={6}>
                            <div className="single-contact-field">
                              <label> Price: </label>
                              <input
                                type="text"
                                name="price"
                                value={`$${article.price} / Day`}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="single-contact-field">
                              <input
                                name="name"
                                type="text"
                                required
                                placeholder={t("contact_page.name")}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="single-contact-field">
                              <input
                                type="tel"
                                name="phone"
                                required
                                placeholder={t("contact_page.phone")}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="single-contact-field">
                              <input
                                name="email"
                                type="email"
                                required
                                placeholder={t("contact_page.email")}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <div>
                              <label> Start Date: </label>
                              <input
                                type="date"
                                name="start date"
                                required
                                defaultValue={date}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <div>
                              <label> End Date: </label>
                              <input
                                type="date"
                                name="End date"
                                required
                                defaultValue={date2}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="single-contact-field">
                              <textarea
                                name="additional information"
                                placeholder="Additional Information"
                                defaultValue={""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="single-contact-field">
                              <button type="submit" className="gauto-theme-btn">
                                <FaPaperPlane /> Send Request
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </Container>

        {/* <ImageGallery items={comments.comment} /> */}
      </section>
      <Footer />
    </>
  );
};

export default Rent;

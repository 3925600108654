import {initializeApp} from 'firebase/app'
import { getAuth } from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyBphbd2gd8tsOSxsyo8tPFtFj8d8gqS0oo",
  authDomain: "autokings-1474d.firebaseapp.com",
  projectId: "autokings-1474d",
  storageBucket: "autokings-1474d.appspot.com",
  messagingSenderId: "360635195105",
  appId: "1:360635195105:web:52be20c5c717a1ca4621a0",
  measurementId: "G-D1FXWK6ZY6"
};


  const app = initializeApp(firebaseConfig);

  export const storage = getStorage(app);
  export const db = getFirestore(app);
  export const auth =getAuth(app);
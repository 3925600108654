import React from "react";
import { Link } from "react-router-dom";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import {
  FaStar,
  FaStarHalfAlt,
  FaCar,
  FaCogs,
  FaTachometerAlt,
  FaEmpire,
  FaDesktop,
  FaKey,
  FaLock,
  FaEye,
  FaPaintBrush,
} from "react-icons/fa";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { auth, db } from "../../firebaseConfig";
import { Fragment } from "react";


import Header from "../../components/header";
import PageTitle from "../../components/PageTitle";

import Footer from "../../components/Footer";



import "./style.css";
import Slider from "react-slick";
import ScrollToTop from "../ScrollToTop";

const CarBooking = () => {
  const { t } = useTranslation();

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [comments, setComments] = useState([]);
  const [user] = useAuthState(auth);
  const [images, Setimages] = useState([]);
  const settings = {
    dots: true,
    
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
  };

  
  useEffect(() => {
    const docRef = doc(db, "Cars", id);
    onSnapshot(docRef, (snapshot) => {
      setArticle({ ...snapshot.data(), id: snapshot.id });

      
    });

  
  }, []);

  // useEffect(() => {
  //   const docRef = doc(db, "Cars", id);
  //   onSnapshot(docRef, (snapshot) => {
      

     
  //   });
   
  
  // }, []);


  const commentRef = doc(db, "Cars", id);
  useEffect(() => {
    const docRef = doc(db, "Cars", id);
    onSnapshot(docRef, (snapshot) => {
      setComments(snapshot.data().comments);
     
      Setimages({thumbnail: snapshot.data().comments.comment, original: snapshot.data().comments.comment});
    });
  }, []);


  console.log('testing images: ', images)
  console.log('ted comments: ', comments)
  return (
    <>
   <ScrollToTop />
      <Header />
      <PageTitle
        pageTitle={t("header-navigation.car_booking")}
        pagesub={t("header-navigation.car_booking")}
      />
      <section className="gauto-car-booking section_70">
        <Container>
        {article && (
          <Row>
              
               <Col lg={6}>
               <div className="car-booking-image">
                 <img src={article.imageUrl}
              alt={article.title} style={{ width: "80%", padding: 10 }} />
               </div>
             </Col>
             
           

            
            <Col lg={6}>
              <div className="car-booking-right">
                <p className="rental-tag">{t("rental")}</p>
                <h3>{article.title} {article.model}</h3>
                <div className="price-rating">
                  <div className="price-rent">
                    <h4>
                     ${article.price}<span> / {t("day")}</span>
                    </h4>
                  </div>


              
                </div>
                <p>
                  {" "}
                 {article.description}
                </p>

                <Link to={`/rent/${id}`} style={{color: 'black'}}    className="gauto-btn">
                          {t("researve_now")}
                </Link>
                <div className="car-features clearfix">
                  <ul>
                    <li>
                      <FaCar /> {t("model")}: {article.year}
                    </li>
                   

                  </ul>
                  <ul>
                  <li>
                      <FaCogs /> {article.transmission}
                    </li>
                  </ul>
                  <ul>
                  <li>
                      <FaPaintBrush /> {article.color}
                    </li>
                  </ul>
                </div>
              </div>
            
            </Col>

            <hr></hr>
                <div>
                  {comments == null &&  comments.map(({ commentId, user, comment, userName , createdAt}) => (
                    <div>
                      <h1>nothing here!</h1>
                    </div>
                    ))}

               
          
            <section className=" ">
           
            <Slider {...settings}>
            {comments !== null &&
          comments.map(({ commentId, user, comment, userName , createdAt}) => (
            
              <div className="slide2">
                
                <div
                  className="gauto-main-slide2"
                  style={{ backgroundImage: `url(${comment})`}}
                >
                 
                </div>
              </div>
              ))}
            </Slider>
          </section>
        
                </div>
          </Row>

         
             )}
        </Container>

        {/* <ImageGallery items={comments.comment} /> */}
          
   
      </section>
      <Footer />
  
     
    </>
  );
};

export default CarBooking;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPaperPlane,
  FaPinterest,
  FaSkype,
  FaTwitter,
  FaVimeo,
} from "react-icons/fa";
import carouseel from "../../img/carrousel black.png"
import "./style.css";



const Contact = () => {
  const location = useLocation()
  const hostname = window.location.href;
  console.log('location: ', location.pathname)
  console.log('location host: ', hostname)
  const { t } = useTranslation();

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={7} md={6}>
            <div className="contact-left">
              <h3>{t("contact_page.get_touch")}</h3>
              <form action="https://formsubmit.co/sales@autokings.sg" method="POST" >
                
                <Row>
                  
                  <Col md={6}>
                  
                    <div className="single-contact-field">
                      <input type="text" name="name" required  placeholder={t("contact_page.name")} />
                    </div>

                    <input type="hidden" name="_subject" value="New submission!"></input>
                    <input type="hidden" name="_template" value="box"></input>
                    <input type="hidden" name="_next" value={hostname}></input>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                      required
                        type="email"
                        name="email"
                        placeholder={t("contact_page.email")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                      name="subject"
                      required
                        type="text"
                        placeholder={t("contact_page.subject")}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input type="tel" name="phone" required placeholder={t("contact_page.phone")} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <textarea
                      name="message"
                      required
                        placeholder={t("contact_page.msg")}
                        defaultValue={""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <button type="submit" className="gauto-theme-btn">
                        <FaPaperPlane /> {t("contact_page.send")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="contact-right">
              <h3>{t("contact_page.info_title")} </h3>
              <div className="contact-details">
                <p>
                  <i className="fa fa-map-marker" /> Paya Lebar Square #07-54 (Singapore 409051){" "}
                </p>
                <div className="single-contact-btn">
                  <h4>{t("contact_page.info_email")}</h4>
                  <a href="mailto:sales@autokings.sg">sales@autokings.sg</a>
                </div>
                <div className="single-contact-btn">
                  <h4>{t("contact_page.info_call")}</h4>
                  <a href="tel:+6587874054">+(65)-87874054</a>
                </div>
                <div className="social-links-contact">
                  <h4>{t("contact_page.info_follow")}</h4>
                  <ul>
                  <li>
                   
                    <a href="https://www.facebook.com/autokingsmotor" target="_blank">
                    <FaFacebook />
       
                     </a>
                  </li>
                  <li>
                  <a href="https://www.instagram.com/autokingsmotor/" target="_blank">
                    <FaInstagram />
       
                     </a>
                  </li>
                 
                  <li>
                  <a href="https://www.carousell.sg/u/autokingsmotor/" target="_blank">
                    <img src={carouseel}></img>
       
                     </a>
                  </li>
                </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;

import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/about-page.png";
import img2 from "../../img/cars.png";

import "./style.css";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                <h4>{t("about_page.subtitle")}</h4>
                <h3>{t("about_page.title")}</h3>
                <p>
                Auto Kings Motor is a luxury car rental company that is registered in Singapore and prides itself on providing exceptional services to its clients. The company offers a wide range of luxury cars for rent, which cater to the diverse needs of its clients. Auto Kings Motor is committed to providing its clients with the best possible experience when it comes to luxury car rentals in Singapore. With a focus on customer satisfaction, the company has established itself as one of the leading luxury car rental companies in Singapore.
                </p>
                <p>
                Auto Kings Motor offers a variety of luxury cars for rent, including sedans, sports cars, SUVs, and convertibles. The company's fleet of luxury cars includes some of the most popular brands in the market, such as Lamborghini, McLaren, Porsche, and other continental rides. With such an extensive range of luxury cars available for rent, clients can choose the car that best suits their needs and preferences.
                </p>

                <p>In addition to its impressive range of luxury cars, Auto Kings Motor offers a variety of services and benefits to its clients. These include 24/7 customer support, flexible rental options, and reasonable delivery and pick-up services. The company also offers competitive pricing, making luxury car rentals accessible to a wider range of clients.</p>
               
               <p>
               Auto Kings Motor is dedicated to providing its clients with a seamless and hassle-free experience when it comes to renting luxury cars in Singapore.
               </p>
                <div className="about-page-call">
                  <div className="page-call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-info">
                    <p>{t("need_any_help")}</p>
                    <h4>
                      <a href="tel:12435424">+(65) 9166 6268</a>
                      <br></br>
                      <a href="tel:12435424">+(65) 8757 4054</a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img2} alt="about promo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutPage;
